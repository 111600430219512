<template>
  <div>
    <w-top-bar>Pedidos pendientes de retiro</w-top-bar>

    <div class="h-full p-5 bg-gray-200">
      <div class="grid grid-cols-2 justify-items-stretch pb-4">
        <div class="flex gap-2 justify-self-start">
          <w-input v-model="sku" placeholder="Buscar por SKU" />
        </div>
        <div class="justify-self-end">
          <w-date-range v-model="dateFilter" @change="reload()" />
        </div>
      </div>

      <div class="relative">
        <w-loading class="absolute inset-center-mx mt-10" v-if="loading"></w-loading>

        <div v-else class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Producto
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase text-center bg-gray-50"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(item, index) in itemsFiltered" :key="index">
                <td class="px-6 py-2 whitespace-nowrap">
                  <div>{{ item.name }}</div>
                  <div>
                    <!-- Span badge -->
                    <span
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-500 text-white"
                      >{{ item._id }}</span
                    >
                  </div>
                </td>
                <td class="px-6 text-center py-2 whitespace-nowrap">
                  {{ item.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RetirosPendientes",
  data() {
    return {
      loading: false,
      sku: "",
      dateFilter: {
        // A year ago
        start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        // Now
        end: new Date(),
      },
      items: [],
    };
  },
  watch: {
    dateFilter() {
      this.load();
    },
  },
  async created() {
    this.load();
  },
  computed: {
    itemsFiltered() {
      if (!this.sku) return this.items;

      return this.items.filter(
        (item) => item && item._id && item._id.toLowerCase().includes(this.sku.toLowerCase())
      );
    },
  },
  async mounted() {},
  methods: {
    async load() {
      this.loading = true;
      const result = await this.findPending({
        query: {
          dateFilter: this.dateFilter,
        },
      });

      this.items = result.filter((item) => item);
      this.loading = false;
    },
    ...mapActions("pendientes-retiro", { findPending: "find" }),
  },
};
</script>

<style lang="scss" scoped>
.inset-center-mx {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}
</style>
