import { render, staticRenderFns } from "./pendientes.vue?vue&type=template&id=29608c42&scoped=true&"
import script from "./pendientes.vue?vue&type=script&lang=js&"
export * from "./pendientes.vue?vue&type=script&lang=js&"
import style0 from "./pendientes.vue?vue&type=style&index=0&id=29608c42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29608c42",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WDateRange from '@/components/WDateRange.vue'
import WInput from '@/components/WInput.vue'
import WLoading from '@/components/WLoading.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WDateRange,WInput,WLoading,WTopBar})
